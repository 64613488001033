import * as yup from 'yup';
import { REGEXP_CARD_NUMBER, REGEXP_CVV, REGEXP_EXPIRE_DATE, REGEXP_CARD_HOLDER, } from '@app/constants/validation';
export const cardDetailsValidationSchema = yup.object().shape({
    cardType: yup.string(),
    name: yup.string().nullable()
        .min(2, 'minimum 2 characters').matches(REGEXP_CARD_HOLDER, 'only latin'),
    cardNumber: yup.string()
        .nullable()
        .required('not be empty')
        .matches(REGEXP_CARD_NUMBER, 'incorrect-number'),
    expireDate: yup.string().nullable()
        .required('not be empty')
        .matches(REGEXP_EXPIRE_DATE, 'incorrect-number'),
    cvv: yup.string().nullable()
        .required('not be empty')
        .matches(REGEXP_CVV, 'incorrect-number'),
});
export const convertAssetsValidationSchema = yup.object().shape({});
