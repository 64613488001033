import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import Button from '@shared/ui/buttons/Button';
const Divider = styled.div(props => ({
    border: `1px solid ${props.theme.customColors.button.pill.border}`,
    margin: `${props.theme.spacing_sizes.l}px 0`,
}));
const EmptyPortfoliosList = ({ hasError, activeViewType }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    if (hasError) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Button, { startIcon: PlusIcon, variant: 'outline', color: 'primary-light', size: 'large', style: {
                    padding: theme.spacing_sizes.l,
                    maxHeight: 'unset',
                    lineHeight: '28px',
                    border: `2px dashed ${theme.palette.primary.main}`,
                    width: activeViewType === 'card' ? 428 : '100%',
                    height: activeViewType === 'card' ? 306 : 'auto',
                }, children: t('portfolio.add_portfolio_button') })] }));
};
export default EmptyPortfoliosList;
