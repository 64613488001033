import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ContainerRow } from '@components/styled';
import Button from '@shared/ui/buttons/Button';
import { BodyMediumBold } from '@components/styled/Typography';
import { ReactComponent as SuccessIconComponent } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
import { StepContainer } from '@widgets/sidebars/shared';
const SuccessContainer = styled(ContainerRow)();
const SuccessIcon = styled(SuccessIconComponent)(() => ({
    width: 70,
    height: 70,
}));
const SuccessIconContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    marginBottom: props.theme.spacing_sizes.xs * 2.5,
}));
const SuccessText = styled(BodyMediumBold)(() => ({
    fontSize: 24,
    lineHeight: '30px',
}));
const Success = ({ amount, onSidebarClose, }) => {
    const { t } = useTranslation();
    return (_jsx(SidebarInner, { children: _jsx(SuccessContainer, { children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(SuccessIconContainer, { children: _jsx(SuccessIcon, {}) }), _jsx(SuccessText, { children: Trans({
                                i18nKey: 'overview.balance_management.success.text',
                                values: {
                                    amount: `${amount} ${t('asset_type.USDT', { ns: 'common' })}`,
                                },
                            }) })] })), buttons: (_jsx(Button, { onClick: onSidebarClose, fullWidth: true, children: t('overview.balance_management.success.continue_btn') })) }) }) }));
};
export default Success;
